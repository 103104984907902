import { faReact, faHtml5, faSquareJs, faVk, faDiscord, faGithubAlt, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import  { useEffect, useState } from 'react';

const Title = () => {
  return (
    <>
      <h1 className="title_dev">
        <span className="braces">{ ' { ' }</span>
        Deamingo Dev
        <span className="braces">{ ' } ' }</span>
      </h1>
    </>
  );
};



const Stack = () => {
  const stackItems = useRef([]);

   const [colorTitle, setColor] = useState("#D5BEBE");

  const changeColor = (color) => {
        setColor(color);
    };



  const animateItem = (index) => {
    const item = stackItems.current[index];
    if (item) {
      // Устанавливаем начальные стили для анимации
      item.style.transition = 'transform 1s ease, opacity 1s ease';
      item.style.transform = 'translateY(100px)'; // Падение вниз
      item.style.opacity = '0.5'; // Уменьшаем непрозрачность

      // Возвращаем элемент на место через 1 секунду
      setTimeout(() => {
        item.style.transform = 'translateY(0)';
        item.style.opacity = '1';
      }, 1000);
    }
  };

  return (
    <div className="stackohz">
    <h1 className="stackTitle"  style={{
                    color: colorTitle,
                    transition: 'color 1s ease', // Плавный переход цвета за 1 секунду
                }}>Made on</h1>
          <div className="stackContainer">
        <FontAwesomeIcon
          icon={faReact}
          className="stack"

          ref={(el) => (stackItems.current[0] = el)}
          onClick={() => { animateItem(0); changeColor('#680bab') }}
        />
        <FontAwesomeIcon
          icon={faHtml5}
          className="stack"
          ref={(el) => (stackItems.current[1] = el)}
    onClick={() => { animateItem(1); changeColor('#fff500')}}
        />
        <FontAwesomeIcon
          icon={faSquareJs}
          className="stack"
          ref={(el) => (stackItems.current[2] = el)}
        onClick={() => { animateItem(2); changeColor('#D5BEBE')}}
        />
      </div>
    </div>
  );
};

const Social = () => {
  return(
    <>
    <aside className="social-icon">
  <a
    target="_blank"
    className="social-a mrg_bt"
    href="https://discord.gg/BAduqPArD8"
  >
         <FontAwesomeIcon className="icon-ds mrg_bt" icon={faDiscord}/>
  </a>
  <a
    target="_blank"
    className="social-a mrg_bt"
    href="https://vk.com/id889856563"
  >
   <FontAwesomeIcon className="icon mrg_bt" icon={faVk}/>
  </a>
  <a
    target="_blank"
    className="social-a mrg_bt"
    href="https://t.me/mylifedigitized"
  >
   <FontAwesomeIcon className="icon mrg_bt" icon={faTelegram}/>
  </a>
  <a
    target="_blank"
    className="social-a imgit"
    href="https://github.com/keydrave"
  >
    <FontAwesomeIcon className="icon" icon={faGithubAlt}/>
  </a>
</aside>

    </>
    );
}

const App = () => {
  
  return( <div>

    <Title/>
    <Stack/>
    <Social/>

    </div>
    )
};

export default App;
